import { graphql, Link, useStaticQuery } from 'gatsby'
import React from 'react'

export default function CurrentTravels(props) {
  const { options: { options: { general: { currentTravelsNote, currentTravels } } } } = useStaticQuery(graphql`
    query {
      options: wp {
        options {
          general {
            currentTravelsNote
            currentTravels {
              accent
              link
              title
              content {
                date
                description
                guide
                price
              }
            }
          }
        }
      }
    }
  `)

  return (
    <section className='alt'>
      <div className='inner'>
        <header>
          <h2>Aktuelle Rohstoff-Reisen – Kommen Sie mit!</h2>
        </header>

        <div className='flex-grid'>
          {currentTravels.map(({ accent, content, link, title }) => (
            <article
              key={link}
              className={`flex-column card card--style-${accent}`}
            >
              <header>
                <h3>{title}</h3>
              </header>

              <main>
                <p>{content.description}</p>

                <p>
                  <b>Begleitung</b>: {content.guide}
                  <br />
                  <b>Termin</b>: {content.date}
                  <br />
                  <b>Preis</b>: {content.price}
                </p>
              </main>

              <ul className='actions'>
                <li>
                  <Link
                    aria-label={`Zur aktuellen ${title} Rundreise`}
                    to={'/' + link}
                    className='button'
                  >
                    Zur Reise
                  </Link>
                </li>
              </ul>
            </article>
          ))}
        </div>
        {/* There should probably be a utility class for this */}
        <p style={{ marginTop: '2em' }}>
          {/* TODO: Find a better solution than replacing newlines */}
          <i
            dangerouslySetInnerHTML={{
              __html: currentTravelsNote.replace('\r\n', '<br>'),
            }}
          />
        </p>
      </div>
    </section>
  )
}